import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AffilateTemplate from "../templates/Affilate";
import { DataContext } from "context/DataContext";

class Affilate extends Component {
  static contextType = DataContext;

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { setShowLoading } = this.context;

    // Show the loader
    setShowLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoading(false);
    }
  };

  render() {
    return (
		<div>
			<Helmet>
				<title>TxtCart® | Affilates</title>
			</Helmet>
			<AffilateTemplate />
		</div>
	);
  }
}

export default Affilate;
