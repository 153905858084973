import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

export default function OnboardingStep({
	index,
	mode,
	text,
	isCompleted,
	isLargeScreen,
	isLast,
	handleEnableClick,
	handleSkipClick,
	step,
	buttonTitle
}) {
	const lightMode = mode == "light" ? true : false;
	return (
		<Grid
			container
			lg={12}
			alignItems={"center"}
			margin={"0 15px"}
			justifyContent={"space-between"}
			borderBottom={
				!isLast
					? lightMode
						? "1px solid #E8EBF3"
						: "1px solid #1E202D"
					: "none"
			}
		>
			<Grid item xs={isCompleted ? 12 : 4} lg={isCompleted ? 12 : 8}>
				<Box display={"flex"} alignItems={"center"}>
					{isCompleted && (
						<Box
							mr={"10px"}
							sx={{
								width: "28px",
								height: "28px",
								background: "rgb(12, 132, 254,0.15)",
								borderRadius: "50%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<img
								src="/icons/check.svg"
								style={{
									opacity: "1",
								}}
							/>
						</Box>
					)}
					<Typography
						className={"hello" + index}
						fontSize={isLargeScreen ? "16px" : "14px"}
						color={mode === "light" ? "#000D31" : "#EBECF2"}
						fontWeight={"600"}
						sx={{
							opacity: isCompleted ? "0.35" : "1",
						}}
					>
						{text}
					</Typography>
				</Box>
			</Grid>
			{!isCompleted && (
				<>
					<Grid
						container
						display={{ xs: "none", md: "flex" }}
						xs={5}
						lg={4}
						justifyContent={"end"}
					>
						<Button
							size={"small"}
							sx={{
								padding: "5px 25px",
								border: lightMode ? "1px solid #E8EBF3" : "1px solid #303343",
								color: lightMode ? "#000D31" : "#EBECF2",
								marginRight: "10px",
							}}
							variant="outlined"
							onClick={()=>handleSkipClick(step)}
						>
							<Typography fontSize={"12px"} fontWeight={"500"}>
								Skip
							</Typography>
						</Button>
						<Button
							size="small"
							variant="outlined"
							sx={{
								padding: "5px 40px",
								border: "1px solid #0C84FE",
								color: lightMode ? "#0C84FE" : "#EBECF2",
								minWidth: "123px"
							}}
							onClick={() =>handleEnableClick(step,isCompleted)}
						>
							<Typography fontSize={"12px"} fontWeight={"500"}>
								{buttonTitle}
							</Typography>
						</Button>
					</Grid>
					<Grid
						container
						display={{ xs: "flex", md: "none" }}
						xs={3}
						lg={4}
						justifyContent={"end"}
					>
						<Box>
							<img
								src={
									mode == "light"
										? "/arrow/arrow-right.svg"
										: "/arrow/dark/arrow-right.svg"
								}
							/>
						</Box>
					</Grid>
				</>
			)}
		</Grid>
	);
}
