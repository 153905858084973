/* eslint-disable no-unused-vars */
import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import VideoModal from "../../../components/VideoModal";

export default function Banner({ mode, handleRemove }) {
	const [showVideoModal, setShowVideoModal] = useState(false);
	const theme = useTheme();

	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const lightMode = mode == "light" ? true : false;

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			flexDirection={"column"}
			alignItems={"center"}
			position={"relative"}
			p={belowTablet ? "15px" : "15px 36px"}
			sx={{
				backgroundColor: lightMode ? "#E6F3FF" : "#131522",
				border: "1px solid rgba(0, 129, 255, 0.15);",
				borderRadius: "12px",
				// height:'270px'
			}}
		>
			<Box
				sx={{
					position: "absolute",
					right: "20px",
					top: "10%",
				}}
			>
					<Button
						onClick={() => handleRemove()}
						style={{zIndex:99}}
					>
						<img
							src="/img/cart-recovery/cut.svg"
							alt="close"
							id="pointerCursor"
						/>
					</Button>
			</Box>
			
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "0",
					// top: "35%",
				}}
			>
				<img src="/img/campaigns/campaigns-left-2.png" width={'85%'} />
			</Box>
			
			
		

			<Box
				width={belowTablet ? "90%" : "530px"}
				display={"flex"}
				maxWidth={"530px"}
				textAlign={"center"}
				flexDirection={"column"}
				alignItems={"center"}
				sx={{
					zIndex: 99,
				}}
			>
				<Box mb={"12px"}>
					<Typography
						fontSize={belowTablet ? "20px" : "30px"}
						fontWeight={600}
						color={mode == "light" ? "#000D31" : "#FFF"}
						lineHeight={"normal"}
					>
						Campaigns
					</Typography>
				</Box>
				<Box mb={"17px"}>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						color={mode == "light" ? "#000D31" : "#FFF"}
						sx={{
							opacity: "0.5",
						}}
					>
						Send custom one-time offers to your customers, segments and subscribers. Try templates, AI-Copy assistant, and run a/b tests to bolster SMS Marketing.
					</Typography>
				</Box>
				<Box mb={2}>
					<Button
						size={"small"}
						onClick={() => setShowVideoModal(true)}
						sx={{
							backgroundColor: "#0081FF",
							padding: "5px 15px",
							color: "#FFF",
							":hover": {
								color: lightMode ? "#0081FF" : "#FFF",
							},
						}}
						variant="outlined"
					>
						<img src="/icons/play.svg" />
						<Typography ml={1}>How It Works</Typography>
					</Button>
				</Box>
			</Box>
			
	
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "-64px",
					top: "0",
					// transform: "rotateZ(314deg)",
				}}
			>
				<img src="/img/campaigns/campaigns-right-2.png"  width={'85%'}/>
			</Box>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=sYmTnWyolWU"}
				/>
		</Box>
	);
}

