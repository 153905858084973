import {
	Avatar,
	Box,
	Divider,
	MenuItem,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { CustomSelect, TextInput } from "components/Inputs";
import AddPersonaModal from "components/Modal/Settings/AddPersonaModal";
import React, { useState, useEffect } from "react";
import { DownArrow } from "resources/Icons/Arrows";

export default function Company({ settings, setSettings }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const [selected, setSelected] = useState("");
	const belowSmall = useMediaQuery(theme.breakpoints.down("md"));
	const [modalOpen, setModalOpen] = useState(false);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [shopName, setShopName] = useState('');
	const [carrier, setCarrier] = useState('');
	const [domesticTime, setDomesticTime] = useState('');
	const [internationalTime, setInternationalTime] = useState('');
	const [returnTime, setReturnTime] = useState('');

	useEffect(() => {
		setShopName(settings.shopName);
		setCarrier(settings.carrier);
		setDomesticTime(settings.domesticShipping);
		setInternationalTime(settings.internationalShipping);
		setReturnTime(settings.returns);
		setSelected(settings.persona);
	}, [settings]);
	
	function customSetShopName(value){
		setSettings('shopName', value)
		setShopName(value); 
	}

	function customSetCarrier(value){
		setSettings('carrier', value)
		setCarrier(value); 
	}

	function customSetDomecticTime(value){
		setSettings('domesticShipping', value)
		setDomesticTime(value); 
	}

	function customSetInternationalTime(value){
		setSettings('internationalShipping', value)
		setInternationalTime(value); 
	}

	function customSetReturn(value){
		setSettings('returns', value)
		setReturnTime(value); 
	}

	function customSetSelected(value){
		setSettings('persona', value)
		setSelected(value); 
	}

	return (
		<>
			<Box
				// p={"20px"}
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
				}}
			>
				<Typography
					fontSize={"20px"}
					pl={"20px"}
					pt={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Profile
				</Typography>
				<InputWrapper mt="12px">
					<Box width={"100%"}>
						<CustomLabel>Store Name</CustomLabel>
						<TextInput 
							onChange={e => customSetShopName(e.target.value)}
							value={shopName}
						/>
					</Box>
					<Box width={"100%"}></Box>
					<Box width={"100%"}></Box>
				</InputWrapper>
				<Divider />

				<Typography
					fontSize={"20px"}
					pl={"20px"}
					pt={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Shipping Details
				</Typography>
				<InputWrapper mt="12px">
					<Box width={"100%"}>
						<CustomLabel>Shipping Carrier</CustomLabel>
						<CustomSelect
							placeholder=""
							suffixIcon={<DownArrow />}
							onChange={customSetCarrier}
							value={carrier}
						>
							{shippingCarriers?.map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</CustomSelect>
					</Box>
					<Box width={"100%"}>
						<CustomLabel>Domestic Delivery</CustomLabel>
						<CustomSelect
							placeholder=""
							suffixIcon={<DownArrow />}
							value={domesticTime}
							onChange={customSetDomecticTime}
						>
							{domesticTimes?.map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</CustomSelect>
					</Box>
					<Box width={"100%"}>
						<CustomLabel>International Delivery</CustomLabel>
						<CustomSelect
							placeholder=""
							suffixIcon={<DownArrow />}
							value={internationalTime}
							onChange={customSetInternationalTime}
						>
							{internationalTimes?.map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</CustomSelect>
					</Box>
				</InputWrapper>
				<Divider />

				<Typography
					fontSize={"20px"}
					pl={"20px"}
					pt={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Return Policy
				</Typography>
				<InputWrapper mt="12px">
					<Box width={"100%"}>
						<CustomLabel>Return Policy (Days)</CustomLabel>
						<CustomSelect
							placeholder=""
							suffixIcon={<DownArrow />}
							value={returnTime}
							onChange={customSetReturn}
						>
							{returnPolicy?.map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</CustomSelect>
					</Box>
					<Box width={"100%"}></Box>
					<Box width={"100%"}></Box>
				</InputWrapper>
				<Divider />
				{/* <Typography
					fontSize={"20px"}
					pl={"20px"}
					pt={"20px"}
					fontWeight={600}
					color={theme?.palette?.pageHeading}
				>
					Consent Format
				</Typography>
				<InputWrapper mt="12px">
					<Box width={"100%"}>
						<CustomLabel>
							Indicate which consent format your checkout uses
						</CustomLabel>
						<CustomSelect
							placeholder="Advanced"
							suffixIcon={<DownArrow />}
							onChange={() => {
								// console.info(e.target.value);
							}}
						>
							{demoOption?.map((item, index) => {
								return (
									<MenuItem key={index} value={item}>
										{item}
									</MenuItem>
								);
							})}
						</CustomSelect>
					</Box>
					<Box width={"100%"}></Box>
					<Box width={"100%"}></Box>
				</InputWrapper> */}
			</Box>
			<Box
				mt={"20px"}
				p={"20px"}
				sx={{
					borderRadius: "12px",
					border: `1px solid ${theme?.palette?.card?.border}`,
					backgroundColor: theme?.palette?.card?.background,
				}}
			>
				<Typography
					fontSize={"20px"}
					fontWeight={600}
					mb={"19px"}
					color={theme?.palette?.pageHeading}
				>
					Select Persona
				</Typography>
				<Box
					display={"Flex"}
					gap={"12px"}
					flexDirection={belowSmall ? "column" : "row"}
					mb={"12px"}
				>
					{personDetailsRow1?.map((item, index) => {
						return (
							<PersonaCard
								key={index}
								name={item?.name}
								tag={item?.tag}
								img={item?.img}
								selected={selected}
								setSelected={customSetSelected}
							/>
						);
					})}
					{/* <Box
						display={"flex"}
						p={"14px"}
						id="pointerCursor"
						alignItems={"center"}
						flexWrap={"wrap"}
						gap={"13px"}
						className="dashed-border"
						sx={{
							border: `1px solid #91C1FF`,
							borderRadius: "4px",
							width:isMobile?'100%': "25%",
						}}
						onClick={() => {
							setModalOpen(true);
						}}
					>
						<Box
							display={"flex"}
							justifyContent={"center"}
							flexDirection={"row"}
							alignItems={"center"}
							margin={"auto"}
						>
							<Typography
								color={mode == "light" ? "#0A0A18" : "#FFF"}
								fontSize={"16px"}
								fontWeight={600}
								lineHeight={"normal"}
								width={"180px"}
							>
								Add Custom Persona
							</Typography>
							<img
								src="img/users/addIcon.svg"
								style={{
									marginLeft: "8px",
								}}
							/>
						</Box>
					</Box> */}
				</Box>
			</Box>
			<AddPersonaModal
				open={modalOpen}
				handleClose={() => setModalOpen(false)}
			/>
		</>
	);
}

const InputWrapper = ({ children }) => {
	const theme = useTheme();
	const belowSmall = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box p={"12px 20px 20px 20px"}>
			<Box
				display={"Flex"}
				gap={"12px"}
				flexDirection={belowSmall ? "column" : "row"}
			>
				{children}
			</Box>
		</Box>
	);
};
const CustomLabel = ({ children }) => {
	return (
		<Typography fontSize={14} fontWeight={500} color={"#808698"} mb={"8px"}>
			{children}
		</Typography>
	);
};
const shippingCarriers = [
	"USPS",
	"UPS",
	"FedEx",
	"DHL",
	"Canada Post",
	"Royal Mail",
	"Email (Digital Product)"
];

const domesticTimes = [
	"2-3 Business Days",
	"4-7 Business Days",
	"7-10 Business Days",
	"Email (Digital Product)"
];

const internationalTimes = [
	"7-10 Business Days",
	"10-20 Business Days",
	"20-30 Business Days",
	"30+ Business Days",
	"Email (Digital Product)"
];

const returnPolicy = [
	"7 Days",
	"14 Days",
	"30 Days",
	"No Returns"
];


const PersonaCard = ({ name, tag, img, selected, setSelected }) => {
	const theme = useTheme();
	const { mode } = theme.palette;
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box
			id="pointerCursor"
			display={"flex"}
			p={"14px"}
			gap={"13px"}
			sx={{
				border:
					selected == name
						? `1px solid #0081FF`
						: `1px solid ${theme?.palette?.card?.border}`,
				borderRadius: "4px",
				width: isMobile ? "100%" : "25% ",
				position: "relative",
			}}
			onClick={() => {
				setSelected(name);
			}}
		>
			<Box>
				<Avatar alt="Remy Sharp" src={img} />
			</Box>
			<Box>
				<Typography
					mb={"4px"}
					color={mode == "light" ? "#0A0A18" : "#FFF"}
					fontSize={"16px"}
					fontWeight={600}
					lineHeight={"normal"}
				>
					{name}
				</Typography>
				<Typography
					color={"#808698"}
					fontSize={"14px"}
					fontWeight={400}
					lineHeight={"normal"}
				>
					{tag}
				</Typography>
			</Box>

			{selected == name ? (
				<Box
					sx={{
						position: "absolute",
						top: "9px",
						right: "12px",
					}}
				>
					<Box
						sx={{
							height: "20px",
							width: "20px",
							borderRadius: "50%",
							backgroundColor: "#0081FF",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img src="img/users/selected.svg" />
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						position: "absolute",
						top: "0px",
						right: "12px",
					}}
				>
					<img src="img/users/more.svg" />
				</Box>
			)}
		</Box>
	);
};

const personDetailsRow1 = [
	{
		name: "Lindsay",
		tag: "Recommended",
		img: "img/users/lindsay.svg",
	},
	{
		name: "Chris",
		tag: "Recommended",
		img: "img/users/chris.svg",
	},
];
