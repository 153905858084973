import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CampaignPlannerTemplate from "./../templates/Campaign-Planner";
import { DataContext } from "context/DataContext";
import { txtCartFetch } from "utilifyFunctions";
import CampaignPlannerLockedTemplate from "./LockedPages/Campaign-Planner";

class CampaignPlanner extends Component {
  static contextType = DataContext;

	constructor(props) {
		super(props);
		this.state = {
		  verified: true
		};
	  }
		componentDidMount() {
			this.load();
		}
	
		load = async function(){        
			const { setShowLoading } = this.context;
			setShowLoading(true);
	
			const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/verify?id=Starter");
			this.setState({verified: response});
			try {
				await new Promise((resolve) => setTimeout(resolve, 500));
	
				setShowLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setShowLoading(false);
			}
		}

  	render() {
    	const { verified } = this.state;
		if(verified){
			return (
				<div>
				<Helmet>
					<title>TxtCart® | Campaign Planner</title>
				</Helmet>
				<CampaignPlannerTemplate />
				</div>
			);
		} else{
			return (
				<div>
				<Helmet>
					<title>TxtCart® | Campaign Planner</title>
				</Helmet>
				<CampaignPlannerLockedTemplate />
				</div>
			);
		}
    }
}

export default CampaignPlanner;
