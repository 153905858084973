import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { DataContext } from "context/DataContext";
import InsightsTemplate from "templates/Analytics/Insights";
import { txtCartFetch } from "utilifyFunctions";
import InsightsLocked from "templates/LockedPages/Insights";
class Insights extends Component {
	static contextType = DataContext;

	constructor(props) {
	  super(props);
	  this.state = {
		verified: true
	  };
	}
	  componentDidMount() {
		  this.load();
	  }
  
	  load = async function(){        
		  const { setShowLoading } = this.context;
		  setShowLoading(true);
  
		  const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/verify?id=Business");
		  this.setState({verified: response});
		  try {
			  await new Promise((resolve) => setTimeout(resolve, 500));
  
			  setShowLoading(false);
		  } catch (error) {
			  console.error("Error fetching data:", error);
			  setShowLoading(false);
		  }
	  }

	render() {
		const { verified} = this.state; 
		if(verified){
			return (
				<div>
					<Helmet>
						<title>TxtCart® | Insights</title>
					</Helmet>
					<InsightsTemplate />
				</div>
			);
		} else{
			return (
				<div>
					<Helmet>
						<title>TxtCart® | Insights</title>
					</Helmet>
					<InsightsLocked />
				</div>
			);
		}
	}
}

export default Insights;
