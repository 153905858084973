import React, { useEffect, useState } from "react";
import {
	MenuItem,
	MenuList,
	ListItemIcon,
	ListItemText,
	Divider,
	Box,
	Typography,
	Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { txtCartFetch } from "../../../../utilifyFunctions.js";

export default function UserMenu({ mode,onClose }) {
	const navigate = useNavigate();
	const [menu, setMenu] = useState([]);

	const handleLogout = async () => {
		console.log("handleLogout");
		localStorage.clear();
		let response = await txtCartFetch("POST",process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/login/logout");
		navigate("/login");
	};
	
	let userMenuMock = [];

	const theme = useTheme();
	const { themeToggler } = theme;

	const handleClick = (value) => {
		if (value?.link) {
			onClose()
			if (value?.isExternalLink) {
				window.open(value?.link, "_blank");
			} else {
				navigate(value?.link);
			}
		}
	};

	useEffect(() => {
		loadMenu();
		return;
	}, []);

	const loadMenu = async () =>{
		const response = await txtCartFetch("GET", process.env.REACT_APP_BACKEND_DOMAIN + "/dashboard/record/shop/domain");
		if(response){
			setMenu([
				{
					img: "/icons/user-menu/user.svg",
					darkImg: "/icons/user-menu/dark/user.svg",
					title: "Account Settings",
					link:'/settings'
				},
				{
					img: "/icons/user-menu/billing.svg",
					darkImg: "/icons/user-menu/dark/billing.svg",
					title: "Billing",
					link:'/settings?tab=billing'
				},
				{
					img: "/icons/user-menu/link-external-01-alt.svg",
					darkImg: "/icons/user-menu/dark/link-external-01-alt.svg",
					title: "Go to Shopify Admin",
					link:'https://' + response.myShopifyDomain + "/admin",
					isExternalLink:true
				},
				{
					img: "/icons/user-menu/chrome.svg",
					darkImg: "/icons/user-menu/dark/chrome.svg",
					title: "Go to Store",
					link:'https://' + response.domain,
					isExternalLink:true
				},
				{
					img: "/icons/user-menu/affiliates.svg",
					darkImg: "/icons/user-menu/dark/affiliates.svg",
					title: "Affiliates",
					link:'https://affiliates.heymantle.com/aff/join/bf3ef4b1-ddc5-4ace-96ba-8a58b0a2d9d6',
					isExternalLink:true
				},
			]);
		}
	}
	return (
		<Box
			bgcolor={mode === "light" ? "#FFF" : "#0F111B"}
			border={mode === "light" ? "1px solid #E8EBF3" : "1px solid #1E202D"}
			borderRadius={"0px 0px 8px 8px"}
		>
			<MenuList disablePadding id="userMenu" sx={{ py: 0 }}>
				{menu?.map((item, index) => {
					return (
						<MenuItem key={index}  onClick={() => handleClick(item)}>
							<ListItemIcon>
								<img src={mode == "light" ? item?.img : item?.darkImg} />
							</ListItemIcon>
							<ListItemText>
								<Typography fontSize={"14px"} color={"#808698"}>
									{item?.title}
								</Typography>
							</ListItemText>
						</MenuItem>
					);
				})}

				<Divider />
				<MenuItem
					disableRipple
					sx={{
						"&:hover": {
							backgroundColor: "transparent", // Remove hover effect
						},
						"&:focus": {
							backgroundColor: "transparent", // Remove click effect
						},
					}}
				>
					<ListItemIcon>
						<img
							src={
								mode == "light"
									? "/icons/user-menu/dark-mode.svg"
									: "/icons/user-menu/dark/dark-mode.svg"
							}
							alt="Dark Mode"
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography fontSize={"14px"} color={"#808698"}>
							Dark Mode
						</Typography>
					</ListItemText>
					<Typography variant="body2" color="text.secondary">
						<Switch
							checked={mode === "light" ? false : true}
							onChange={themeToggler}
							size="small"
						/>
					</Typography>
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<img
							src={
								mode == "light"
									? "/icons/user-menu/logout.svg"
									: "/icons/user-menu/dark/logout.svg"
							}
							alt="Logout"
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography fontSize={"14px"} color={"#808698"}>
							Logout
						</Typography>
					</ListItemText>
				</MenuItem>
			</MenuList>
		</Box>
	);
}
