import React, { useState } from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	Modal,
	Button,
	RadioGroup,
} from "@mui/material";
import CustomRadioButton from "components/Inputs/RadioButton";
import { BackArrow } from "resources/Icons/Arrows";

export default function CancelPlan({ open, handleClose, settings }) {
	const theme = useTheme();
	const { mode } = theme.palette;
	const lightMode = mode == "light" ? true : false;
	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const [cancelReason, setCancelReason] = useState(1);
	const [step, setStep] = useState(1);

	function handleCancelClick(step){
		console.log('handle cancel', step); 
		if(step === 2){
			let name = settings.myshopifyDomain.split('.');

			window.location.replace("https://admin.shopify.com/store/"+name[0] + "/settings/apps?tab=installed");
		} else{
			setStep(2);
		}
	}
	return (
		<Modal
			open={open}
			id="cancel-Plan"
			aria-labelledby="cancel-Plan"
			aria-describedby="cancel-Plan"
		>
			<MainContainer lightMode={lightMode} belowTablet={belowTablet}>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Box width={"50%"}>
						<img src="/img/cancel-plan.png" />
					</Box>
					<Box width={"50%"} position={"relative"} display={"flex"}>
						{step == 1 && (
							<StepOne
								lightMode={lightMode}
								cancelReason={cancelReason}
								setCancelReason={setCancelReason}
							/>
						)}
						{step == 2 && (
							<StepTwo
								lightMode={lightMode}
								cancelReason={cancelReason}
								setStep={setStep}
								setCancelReason={setCancelReason}
							/>
						)}

						<Box
							display={"flex"}
							justifyContent={"end"}
							position={"absolute"}
							bottom={"0"}
							right={"0"}
						>
							<Button
								size={"small"}
								sx={{
									backgroundColor: "#0081FF",
									padding: "11px 20px",
									color: "#FFF",
									":hover": {
										color: mode == "light" ? "#0C0D17" : "#FFF",
									},
								}}
								variant="outlined"
								onClick={() => {
									handleCancelClick(1);
									handleClose();
								}}
							>
								<Typography fontSize={"14px"} fontWeight={"600"}>
									Keep My Subscription
								</Typography>
							</Button>
							<Button
								size={"small"}
								disableRipple
								sx={{
									backgroundColor: "transparent",
									padding: "11px 20px",
									border: "none",
									height: "40px",
									color: "#B7B7B7",
									":hover": {
										// color: mode == "light" ? "#0C0D17" : "#FFF",
										background: "transparent",
										border: "none",
									},
								}}
								variant="outlined"
								onClick={() => handleCancelClick(step)}
							>
								<Typography fontSize={"14px"} fontWeight={"600"}>
									{step == 1 ? "Continue Cancelling" : "Cancel Subscription"}
								</Typography>
							</Button>
						</Box>
					</Box>
				</Box>
			</MainContainer>
		</Modal>
	);
}

const MainContainer = ({ children, lightMode, belowTablet }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: belowTablet ? "90%" : "984px",
				backgroundColor: theme?.palette?.card?.background,
				padding: "20px",
				borderRadius: "12px",
				outline: "none",
				border: `1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`,
				":focus-visible": {
					border: "none",
				},

				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				boxShadow: 24,
				maxHeight: "90vh",
				overflowY: "auto",
			}}
		>
			{children}
		</Box>
	);
};
const StepOne = ({ lightMode, cancelReason, setCancelReason }) => {
	const theme = useTheme();

	return (
		<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
			<Box>
				<Typography
					color={theme.palette?.pageHeading}
					fontSize={"30px"}
					fontWeight={600}
					lineHeight={"normal"}
					mb={"24px"}
				>
					Before you go, please let us know why you decided to cancel TxtCart.
				</Typography>
				<Typography
					color={theme.palette?.pageHeading}
					fontSize={"14px"}
					fontWeight={500}
					lineHeight={"20px"}
					sx={{
						opacity: 0.5,
					}}
				>
					Your feedback will help us improve and better the experience for
					thousands.
				</Typography>
				<Box
					border={`1px solid ${lightMode ? "#DADEEA" : "#1E202D"}`}
					p={"20px"}
					borderRadius={"12px"}
					mt={"24px"}
				>
					<RadioGroup
						value={cancelReason}
						onChange={(e) => {
							setCancelReason(e?.target?.value);
						}}
					>
						<Box display={"flex"} flexDirection={"column"} gap={"18px"}>
							{reasonMock?.map((item, index) => {
								return (
									<Box
										mb={"13px"}
										key={index}
										display={"flex"}
										alignItems={"center"}
									>
										<CustomRadioButton value={index + 1} />
										<Typography
											color={theme.palette?.pageHeading}
											fontSize={"16px"}
											fontWeight={600}
											lineHeight={"20px"}
										>
											{item}
										</Typography>
									</Box>
								);
							})}
						</Box>
					</RadioGroup>
				</Box>
			</Box>
		</Box>
	);
};
const StepTwo = ({  setStep }) => {
	const theme = useTheme();
	return (
		<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
			<Box>
				<Box
					position={"absolute"}
					id={"pointerCursor"}
					onClick={() => setStep(1)}
					sx={{
						left: "0",
						top: "10px",
					}}
				>
					<Box display={"flex"} gap={"13px"}>
						<Box
							bgcolor="rgba(0, 129, 255, 0.10)"
							borderRadius={"50%"}
							// width={"32px"}
							p={"0px 3px"}
						>
							<BackArrow />
						</Box>
						<Typography
							color={theme.palette?.pageHeading}
							fontSize={"16px"}
							fontWeight={500}
							lineHeight={"normal"}
						>
							Go Back
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box>
				<Typography
					color={theme.palette?.pageHeading}
					fontSize={"30px"}
					fontWeight={600}
					lineHeight={"normal"}
					mb={"39px"}
				>
					Keep your current plan, for less!
				</Typography>
				<Typography
					color={theme.palette?.pageHeading}
					fontSize={"14px"}
					fontWeight={500}
					lineHeight={"20px"}
					mb={"23px"}
					sx={{
						opacity: 0.5,
					}}
				>
					We value your business and would love to continue to work together. You can save 17% with an Annual Plan!
				</Typography>
				<Typography
					color={theme.palette?.pageHeading}
					fontSize={"16px"}
					fontWeight={600}
					lineHeight={"normal"}
					mb={"39px"}
				>
					What you’ll lose:
				</Typography>
				<Box display={"flex"} flexDirection={"column"} gap={"20px"}>
					{lossesMock?.map((item, index) => {
						return (
							<Box key={index} display={"flex"} gap={"51px"}>
								<Box display={"flex"} gap={"9px"} alignItems={"center"}>
									{" "}
									<img src="/icons/cross.svg" width={"11px"} />
									<Box width={"167px"}>{item?.first}</Box>
								</Box>
								<Box display={"flex"} gap={"9px"} alignItems={"center"}>
									{" "}
									<img src="/icons/cross.svg" width={"11px"} />
									<Box>{item?.second}</Box>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
};



const reasonMock = [
	"Didn’t get results",
	"Was confusing to setup",
	"Too expensive",
	"Wasn’t what I was looking for",
	"Just browsing",
	"Moving to another SMS platform",
];

const lossesMock = [
	{
		first: "SMS Sales Channel",
		second: "Contextual Insights",
	},
	{
		first: "20% Revenue Lift",
		second: "Reporting",
	},
	{
		first: "up to 5 seats",
		second: "Custom Branded Links",
	},
	{
		first: "Subscriber Analytics",
		second: "Advanced Analytics",
	},
	{
		first: "SMS AI Assistant",
		second: "24/7 Support",
	},
];
