/* eslint-disable no-unused-vars */
import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import VideoModal from "../../../components/VideoModal";

export default function Banner({ mode, closeTab, }) {
	const [showVideoModal, setShowVideoModal] = useState(false);
	const theme = useTheme();
	const [isOpen, setIsOpen] = useState(false);

	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const lightMode = mode == "light" ? true : false;
	return lightMode ? (
		<LightBanner
			belowTablet={belowTablet}
			setShowVideoModal={setShowVideoModal}
			showVideoModal={showVideoModal}
			closeTab={closeTab}
		/>
	) : (
		<DarkBanner
			belowTablet={belowTablet}
			setShowVideoModal={setShowVideoModal}
			showVideoModal={showVideoModal}
			closeTab={closeTab}
		/>
	);
}

const LightBanner = ({ belowTablet, setShowVideoModal, showVideoModal, closeTab }) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			flexDirection={"column"}
			alignItems={"center"}
			position={"relative"}
			p={belowTablet ? "15px" : "15px 36px"}
			sx={{
				backgroundColor: "#E6F3FF",
				border: "1px solid rgba(0, 129, 255, 0.15);",
				borderRadius: "12px",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					right: "20px",
					top: "10%",
					zIndex: "99",
				}}
			>
				<img
					src="/img/cart-recovery/cut.svg"
					alt="close"
					onClick={()=>closeTab(false)}
					id="pointerCursor"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "23px",
					top: "13%",
					zIndex: "99",
				}}
			>
				<img src={`/img/analytics/mask-1.svg`}  />
			</Box>

			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "8%",
					bottom: "-5px",
				}}
			>
				<img
					src={`/img/analytics/card-1.svg`}
					width={"80%"}
					// loading="lazy"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "5%",
					bottom: "-5px",
				}}
			>
				<img
					src={`/img/analytics/left-shade.svg`}
					width={"80%"}
					// loading="lazy"
				/>
			</Box>
			<Box
				width={belowTablet ? "90%" : "600px"}
				display={"flex"}
				maxWidth={"600px"}
				textAlign={"center"}
				flexDirection={"column"}
				alignItems={"center"}
				sx={{
					zIndex: 99,
				}}
			>
				<Box mb={"12px"}>
					<Typography
						fontSize={belowTablet ? "20px" : "30px"}
						fontWeight={600}
						color={"#000D31"}
						lineHeight={"normal"}
					>
						Analytics
					</Typography>
				</Box>
				<Box mb={"17px"}>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						color={"#000D31"}
						sx={{
							opacity: "0.5",
						}}
					>
						Make better marketing decisions by understanding true SMS metrics.
					</Typography>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						color={"#000D31"}
						sx={{
							opacity: "0.5",
						}}
					>
						Get in depth analytics into your SMS subscriber and revenue data.
					</Typography>
				</Box>
				<Box mb={2}>
					<Button
						size={"small"}
						onClick={() => setShowVideoModal(true)}
						sx={{
							backgroundColor: "#0081FF",
							padding: "5px 15px",
							color: "#FFF",
							":hover": {
								color: "#0081FF",
							},
						}}
						variant="outlined"
					>
						<img src="/icons/play.svg" />
						<Typography ml={1}>How It Works</Typography>
					</Button>
				</Box>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "0%",
					bottom: "-5px",
					// zIndex:'99'
				}}
			>
				<img
					src={`/img/analytics/card-2.svg`}
					width={"80%"}
					style={{}}
					// loading="lazy"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "-3%",
					bottom: "-5px",
					// zIndex:'99'
				}}
			>
				<img
					src={`/img/analytics/right-shade.svg`}
					width={"80%"}
					style={{}}
					// loading="lazy"
				/>
			</Box>

			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "206px",
					top: "0%",
					// transform: "rotateZ(314deg)",
				}}
			>
				<img src={`/img/analytics/mask-1.svg`} />
			</Box>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=qTpcfMBgqF0"}
				/>
		</Box>
	);
};

const DarkBanner = ({ belowTablet, setShowVideoModal, showVideoModal, closeTab }) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			flexDirection={"column"}
			alignItems={"center"}
			position={"relative"}
			p={belowTablet ? "15px" : "15px 36px"}
			sx={{
				backgroundColor: "#131522",
				border: "1px solid rgba(0, 129, 255, 0.15);",
				borderRadius: "12px",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					right: "20px",
					top: "10%",
					zIndex: "99",
				}}
			>
				<img
					src="/img/cart-recovery/cut.svg"
					alt="close"
					onClick={()=>closeTab(false)}
					id="pointerCursor"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "14px",
					top: "11%",
					zIndex: "99",
				}}
			>
				<img src={`/img/analytics/mask-1-dark.svg`} />
			</Box>

			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "9%",
					bottom: "-5px",
				}}
			>
				<img
					src={`/img/analytics/card-1-dark.svg`}
					width={"80%"}
					// loading="lazy"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "5%",
					bottom: "-5px",
				}}
			>
				<img
					src={`/img/analytics/left-shade-dark.svg`}
					width={"80%"}
					// loading="lazy"
				/>
			</Box>
			<Box
				width={belowTablet ? "90%" : "600px"}
				display={"flex"}
				maxWidth={"600px"}
				textAlign={"center"}
				flexDirection={"column"}
				alignItems={"center"}
				sx={{
					zIndex: 99,
				}}
			>
				<Box mb={"12px"}>
					<Typography
						fontSize={belowTablet ? "20px" : "30px"}
						fontWeight={600}
						color={"#FFF"}
						lineHeight={"normal"}
					>
						Analytics
					</Typography>
				</Box>
				<Box mb={"17px"}>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						color={"#FFF"}
						sx={{
							opacity: "0.5",
						}}
					>
						Make better marketing decisions by understanding true SMS metrics.
					</Typography>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						color={"#FFF"}
						sx={{
							opacity: "0.5",
						}}
					>
						Get in depth analytics into your SMS subscriber and revenue data.
					</Typography>
				</Box>
				<Box mb={2}>
					<Button
						size={"small"}
						onClick={() => setShowVideoModal(true)}
						sx={{
							backgroundColor: "#0081FF",
							padding: "5px 15px",
							color: "#FFF",
							":hover": {
								color: "#FFF",
							},
						}}
						variant="outlined"
					>
						<img src="/icons/play.svg" />
						<Typography ml={1}>How It Works</Typography>
					</Button>
				</Box>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "1%",
					bottom: "-5px",
					// zIndex:'99'
				}}
			>
				<img
					src={`/img/analytics/card-2-dark.svg`}
					width={"80%"}
					style={{}}
					// loading="lazy"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "-3%",
					bottom: "-5px",
					// zIndex:'99'
				}}
			>
				<img
					src={`/img/analytics/right-shade-dark.svg`}
					width={"80%"}
					style={{}}
					// loading="lazy"
				/>
			</Box>

			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "181px",
					top: "-2%",
					// transform: "rotateZ(314deg)",
				}}
			>
				<img src={`/img/analytics/mask-1-dark.svg`} />
			</Box>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=qTpcfMBgqF0"}
				/>
		</Box>
	);
};
