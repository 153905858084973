import React from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import Card from "../../../../components/Card";
export default function RecommendedSetps() {
	const cardMockfirstRow = [
		{
			title: "Create your first SMS campaign",
			description:
				"Customize a 1-time send mass blast to your subscriber list. Let them know of a special promo or sale.",
			action: "Create Campaign",
			link:'/campaigns/create-campaigns'
		},
		{
			title: "Connect Integrations",
			description:
				"Explore our App Marketplace and recommended apps to bring even more value to your store.",
			action: "Connect",
			link:'/integrations'
		},
		{
			title: "Browse our SMS Campaign Planner",
			description:
				"Not sure when to send an SMS Blast? We got you with common holidays and templates you can plug in and easily make sales with.",
			action: "View Planner",
			link: "/campaign-planner"
		},
	];
	const cardMockSecondRow = [
		{
			title: "View your Analytics",
			description:
				"Checkout our Advanced SMS Analytics and get all the data you need to make more informed decisions for your business.",
			action: "View Analytics",
			link: "/analytics"
		},
		{
			title: "View your Trust Hub",
			description:
				"Review key SMS marketing compliance rules and regulations and ensure you are abiding.",
			action: "View Compliance",
			link: "/trust-hub"
		},
		{
			title: "Confirm Your Settings",
			description:
				"Review your account, company, messaging, and discount settings to maximize results",
			action: "View Settings",
			link: "/settings"
		},
	];
	const cardMockThirdRow = [
		{
			title: "Upgrade to 10-DLC Number",
			description:
				"Buy a unique local area code 10 digit long code number and get higher throughputs in campaigns.",
			action: "Upgrade 10-DLC",
			link:"/settings?tab=messaging"
		},
		{
			title: "Upgrade your Plan",
			description:
				"Unlock new features, lower commission and better rates by choosing a higher plan.",
			action: "Upgrade Plan",
			link:"/settings?tab=billing"
		},
		{
			title: "Become an Affiliate",
			description:"Earn a recurring revenue by recommending TxtCart. Get paid 15% of total spend per month.",
			action: "Refer & Earn",
			link:"https://affiliates.heymantle.com/aff/join/bf3ef4b1-ddc5-4ace-96ba-8a58b0a2d9d6",
			isExternal:true
		},
	];
	const getMode = useTheme();
	const mode = getMode?.palette?.mode;
	return (
		<Box>
			<Typography mb={2} mt={2} fontSize={"18px"} fontWeight={"600"}>
				Recommended next steps
			</Typography>

			<Box
				sx={{
					borderRadius: "12px",
				}}
			>
				<Grid
					mb={2.5}
					container
					gap={{xs:1.5,lg:0}}
					flexWrap={"wrap"}
					justifyContent={"space-between"}
				>
					{cardMockfirstRow?.map((item, index) => {
						return (
							<Grid
								key={index}
								container
								id="metricCard"
								item
								xs={12}
								sm={12}
								lg={3.9}
								flexDirection={"row"}
								sx={{
									padding: "16px",
									borderRadius: "12px",
									border:
										mode === "light"
											? "1px solid #E8EBF3"
											: "1px solid #1E202D",
									background: getMode.palette?.card?.background,
								}}
							>
								<Card 
									title={item?.title}
									description={item?.description}
									img={item?.img}
									action={item?.action}
									link={item?.link}
									mode={mode}
									isExternal={item?.isExternal}
								/>
							</Grid>
						);
					})}
				</Grid>
				<Grid
					mb={2.5}
					container
					gap={{xs:1.5,lg:0}}
					flexWrap={"wrap"}
					justifyContent={"space-between"}
				>
					{cardMockSecondRow?.map((item, index) => {
						return (
							<Grid
								key={index}
								container
								id="metricCard"
								item
								xs={12}
								sm={12}
								lg={3.9}
								flexDirection={"row"}
								sx={{
									padding: "16px",
									borderRadius: "12px",
									border:
										mode === "light"
											? "1px solid #E8EBF3"
											: "1px solid #1E202D",
									background: getMode.palette?.card?.background,
								}}
							>
								<Card
									title={item?.title}
									description={item?.description}
									img={item?.img}
									action={item?.action}
									link={item?.link}
									mode={mode}
									isExternal={item?.isExternal}
								/>
							</Grid>
						);
					})}
				</Grid>
				<Grid
					mb={2.5}
					container
					gap={{xs:1.5,lg:0}}
					flexWrap={"wrap"}
					justifyContent={"space-between"}
				>
					{cardMockThirdRow?.map((item, index) => {
						return (
							<Grid
								key={index}
								container
								id="metricCard"
								item
								xs={12}
								sm={12}
								lg={3.9}
								flexDirection={"row"}
								sx={{
									padding: "16px",
									borderRadius: "12px",
									border:
										mode === "light"
											? "1px solid #E8EBF3"
											: "1px solid #1E202D",
									background: getMode.palette?.card?.background,
								}}
							>
								<Card
									title={item?.title}
									description={item?.description}
									img={item?.img}
									action={item?.action}
									link={item?.link}
									mode={mode}
									isExternal={item?.isExternal}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Box>
	);
}
