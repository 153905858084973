import { useState } from "react";
import {
    CannyProvider,
    CannyChangelog
} from "react-canny";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const APP_ID = "650737828f0cea0954c743a6";


const CustomNotification = ({showNotification}) =>{
    return  showNotification ? (
      <div>
      <span className="notification-dot">&nbsp;</span>
      <span className="notification-dot outer-circle">&nbsp;</span>
      </div>
    ) : <div></div>
}

const CustomButton = (props) => {
  const [showNotification, setShowNotification] = useState(true);

  const hideNotificationIcon = (event) => {
    console.log("clicked");
      setShowNotification(false);
      console.log(showNotification);
  };

    const theme = useTheme();
    const { mode } = theme.palette;
    return (
        <IconButton
        {...props}
        size="small"
        aria-label="user-menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={hideNotificationIcon}
      >
        <CustomNotification
          showNotification = {showNotification}
        >
        </CustomNotification>
        <img
          src={
            mode == "light"
              ? "/topbar/bell.svg"
              : "/topbar/dark/bell.svg"
          }
          alt="Bell Icon"
        />
      </IconButton> 
    );
};

const Canny = () => {
    return (
        <CannyProvider appId={APP_ID}>
            <CannyChangelog
                align="top"
                position="left"
                component={CustomButton}>
                Change Log
            </CannyChangelog>
        </CannyProvider>
    );
};

export default Canny;