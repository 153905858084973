import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useState} from "react";
import VideoModal from "components/VideoModal";

export default function Tutorial({ mode, closeTab}) {
	const [showVideoModal, setShowVideoModal] = useState(false);
	const theme = useTheme();

	const belowTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const lightMode = mode == "light" ? true : false;
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			flexDirection={"column"}
			alignItems={"center"}
			position={"relative"}
			p={belowTablet ? "15px" : "15px 36px"}
			sx={{
				backgroundColor: lightMode ? "#E6F3FF" : "#131522",
				border: "1px solid rgba(0, 129, 255, 0.15);",
				borderRadius: "12px",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					right: "20px",
					top: "10%",
				}}
			>
				<Button
					onClick={() => closeTab()}
					style={{zIndex:99}}
				>
					<img
						src="/img/cart-recovery/cut.svg"
						alt="close"
						id="pointerCursor"
					/>
				</Button>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "23px",
					top: "35%",
				}}
			>
				<img src="/img/cart.svg" />
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "8%",
					bottom: "-6px",
				}}
			>
				<img src="/img/circle-left.svg" width={"80%"} />
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					left: "8%",
				}}
			>
				<img
					src={`/img/${lightMode ? "mask1" : "mask1-dark"}.png`}
					width={"88%"}
					loading="lazy"
				/>
			</Box>

			<Box
				width={belowTablet ? "90%" : "475px"}
				display={"flex"}
				maxWidth={"550px"}
				textAlign={"center"}
				flexDirection={"column"}
				alignItems={"center"}
				sx={{
					zIndex: 99,
				}}
			>
				<Box mb={"12px"}>
					<Typography
						fontSize={belowTablet ? "20px" : "30px"}
						fontWeight={600}
						color={mode == "light" ? "#000D31" : "#FFF"}
						lineHeight={"normal"}
					>
						Cart Recovery
					</Typography>
				</Box>
				<Box mb={"17px"}>
					<Typography
						fontSize={"14px"}
						fontWeight={500}
						color={mode == "light" ? "#000D31" : "#FFF"}
						sx={{
							opacity: "0.5",
						}}
					>
						Automatically recover abandoned checkouts with Conversational SMS.
						Get live agents + AI working for you and watch your sales soar.
					</Typography>
				</Box>
				<Box mb={2}>
					<Button
						size={"small"}
						onClick={() => setShowVideoModal(true)}
						sx={{
							backgroundColor: "#0081FF",
							padding: "5px 15px",
							color: "#FFF",
							":hover": {
								color: lightMode ? "#0081FF" : "#FFF",
							},
						}}
						variant="outlined"
					>
						<img src="/icons/play.svg" />
						<Typography ml={1}>How It Works</Typography>
					</Button>
				</Box>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "5%",
					// zIndex:'99'
				}}
			>
				<img
					src={`/img/${lightMode ? "mask2" : "mask2-dark"}.png`}
					width={"80%"}
					style={{}}
					loading="lazy"
				/>
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "-2%",
					top: "10px",
				}}
			>
				<img src="/img/circle-right.svg" width={"80%"} />
			</Box>
			<Box
				display={belowTablet ? "none" : "block"}
				sx={{
					position: "absolute",
					right: "23px",
					top: "35%",
					transform: "rotateZ(314deg)",
				}}
			>
				<img src="/img/cart.svg" />
			</Box>
			<VideoModal
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					url={"https://www.youtube.com/watch?v=1I_IZP6Y17k"}
				/>
		</Box>
	);
}
