
export const txtCartFetch = async (method, url,data, avoidAlerts=false) =>{
    try{
        let response; 

        let headers = {};
    
        if(!process.env.REACT_APP_ENVIRONMENT === undefined || process.env.REACT_APP_ENVIRONMENT === null){
            console.error("No environment set");
            return;
        }
    
        if(process.env.REACT_APP_ENVIRONMENT === 'DEVELOPER'){
            headers['ngrok-skip-browser-warning'] = "69420";
        }
    
        if(method === "GET"){
            response =  await fetch(url,{
                headers:headers,
                credentials:"include",
            });
        }
        else if(method === "POST" || method === "PUT"){
            headers["Content-Type"] = "application/json";
            response = await fetch(url, {
                method: method,
                credentials: "include",
                headers:headers,
                body: JSON.stringify(data), 
              });
        } else{
            console.error("Invalid method");
        }
        if (!response.ok) {
            if(response.status === 401 || response.status === 403){
                window.location.replace("/login");
                return;
            } 
            else if(response.status === 402){
                if(!window.location.href.includes('/onboarding')){
                    window.location.replace("/onboarding");
                }
                return;
            } 
            else if(response.status === 491){
                if(window.location.href.includes('/onboarding')){
                    window.location.replace("/");
                }
            }
            else if (response.status > 301){
                console.log(response.status);
                const message = `An error occurred: ${response.statusText}`;
                if (!avoidAlerts) window.alert(message);
                return;
            }
        } else{
            try{
                const body = await response.json();
                return body; 
            } catch(err){
                console.log(err.message);
            }
        }
    } catch(err){
        console.error(err);
    }
}

export const addDays = function(current, days) {
    var newDate = new Date(current);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

const convertFilter = function(filter){
    if(filter === "all_time"){
        return;
    }
    else if(filter === "today"){
        return "HOUR";
    }
    else if(filter === "yesterday"){
        return "HOUR";
    }
    else if(filter === "last_days_7"){
        return "DAY"; 
    } 
    else if(filter === "last_days_30"){
        return "DAY_7+";
    }
    else if(filter === "current_month"){
        return "DAY_7+";
    }
    else if(filter === "last_month"){
        return "DAY_7+"
    }
    else if(filter === "current_year"){
        return "MONTH"
    }
    else if(filter === "last_year"){
        return "MONTH";
    }
    else if("FULL"){
        return "YEAR";
    }
}

export const getDateChartTick = function(dateValue, filter, shortMode){
    let type = convertFilter(filter);
    if(type === "HOUR"){
        let hour = new Date(dateValue).getHours();
        let hour12Clock = hour % 12; 
        let tick = hour12Clock === 0 ? 12: hour12Clock;
        tick--;
        tick = tick === 0 ? "12:00" : tick + ":00";
        if(shortMode){
            if(hour >= 12){
                tick = tick.replace(":00","a");
            } else{
                tick = tick.replace(":00","p");
            }
        }
        return tick;  
    }
    else if(type === "DAY"){
        let day = addDays(new Date(dateValue),-1);
        day = day.toLocaleDateString("default", { weekday: 'short' })
        return day; 
    }
    else if(type === "DAY_7+"){
        let day = addDays(new Date(dateValue),-1);
        console.log(dateValue,day,day.getDate());
        let displayDay = day.getDate();
        let month = shortMode ? day.getMonth()+1 : day.toLocaleDateString("default",{month: "short"});
        return shortMode ? (month + "/"+ displayDay) : (month + " " + displayDay); 
    }
    else if(type === "MONTH"){
        let day = addDays(new Date(dateValue),-1);
        let month =  day.toLocaleDateString("default",{month: "short"});
        return month 
    }
    else if(type === "YEAR"){
        let day = addDays(new Date(dateValue),-1);
        let year = day.getFullYear();
        return year;
    }
}

export const getPreviousPeriodFilter = function(filter){
    if(filter === "today"){
        return "yesterday";
    }
    if(filter === "yesterday"){
        return "days_ago_2"
    }
    else if(filter === "last_days_7"){
        return "days_7_14";
    } 
    else if(filter === "last_days_30"){
        return "days_30_60";
    }
    else if(filter === "current_month"){
        return "last_month";
    }
    else if(filter === "last_month"){
        return "prior_month_2";
    } 
    else if(filter === "current_year"){
        return "last_year";
    }
    else if(filter === "last_year"){
        return "prior_year_2";
    }
}

export const parseDateFilter = function(filter,oldestDate){
    if(filter === "all_time") {
        return null;
    } else{
        //Unix timestamps, start and end of range to calculate
        let start;
        let end; 

        let today = new Date();
        if(filter === "today"){
            today.setHours(0, 0, 0);	
            start = today.getTime();
            end = (addDays(today,1)).getTime();
        }
        else if(filter === "yesterday"){
            today.setHours(0, 0, 0);
            start = (addDays(today,-1)).getTime();
            end = today.getTime(); 
        }
        else if(filter === "days_ago_2"){
            today.setHours(0, 0, 0);
            start = (addDays(today,-2)).getTime();
            end = (addDays(today,-1)).getTime();
        }
        else if(filter === "last_days_7"){
            today.setHours(0, 0, 0);
            start = (addDays(today,-6)).getTime();
            console.log('start', start);
            end = (addDays(today,1)).getTime();
        } 
        else if(filter === "days_7_14"){
            today.setHours(0, 0, 0);
            start = (addDays(today,-14)).getTime();
            end = (addDays(today,-7)).getTime();
        }
        else if(filter === "last_days_30"){
            today.setHours(0, 0, 0);
            start = (addDays(today,-29)).getTime();
            end = (addDays(today,1)).getTime();
        }
        else if(filter === "days_30_60"){
            today.setHours(0, 0, 0);
            start = (addDays(today,-60)).getTime();
            end = (addDays(today,-30)).getTime();
        }
        else if(filter === "current_month"){
            let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            startOfMonth.setHours(0,0,0);
            start = startOfMonth.getTime();
            let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            endOfMonth.setHours(0,0,0)
            end = endOfMonth.getTime();
        }
        else if(filter === "last_month"){
            let lastMonth = addDays(new Date(today.getFullYear(), today.getMonth(), 1),-1);
            let startOfMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
            startOfMonth.setHours(0, 0, 0);
            start =startOfMonth.getTime();
            lastMonth.setHours(23,59,59);	
            end = lastMonth.getTime();
        }
        else if(filter === "prior_month_2"){
            let lastMonth = addDays(new Date(today.getFullYear(), today.getMonth(), 1),-1);
            let lastTwoMonths = addDays(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1),-1);
            let startOfMonth = new Date(lastTwoMonths.getFullYear(), lastTwoMonths.getMonth(), 1);
            startOfMonth.setHours(0, 0, 0);
            start =startOfMonth.getTime();
            lastTwoMonths.setHours(23,59,59);	
            end = lastTwoMonths.getTime();
        }
        else if(filter === "current_year"){
            let startOfYear = new Date(new Date().getFullYear(), 0, 1);
            let endOfYear = addDays(new Date(new Date().getFullYear() + 1, 0, 1),-1);
            startOfYear.setHours(0,0,0);
            endOfYear.setHours(23,59,59);
            start = startOfYear.getTime();
            end = endOfYear.getTime();
        }
        else if(filter === "last_year"){
            let endLastYear = addDays(new Date(new Date().getFullYear(), 0, 1),-1); 
            endLastYear.setHours(23,59,59);	
            start = new Date(endLastYear.getFullYear(), 0, 1).getTime();
            end = endLastYear.getTime();
        }
        else if(filter === "prior_year_2"){
            let endLastYear = addDays(new Date(new Date().getFullYear(), 0, 1),-1);
            let lastTwoYears = addDays(new Date(endLastYear.getFullYear(), 0, 1),-1);
            lastTwoYears.setHours(23,59,59);	
            start = new Date(lastTwoYears.getFullYear(), 0, 1).getTime();
            end = lastTwoYears.getTime();
        }
        else if(filter === "full"){
            start = oldestDate.lastMessage;
            end = Date.now();
        }
        return {
            start:start,
            end:end
        }
    }
}