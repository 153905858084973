import React, { Component } from "react";
import { Helmet } from "react-helmet";
import OnboardingTemplate from 'templates/Onboarding'
import { DataContext } from "context/DataContext";

class Onboarding extends Component {
	static contextType = DataContext;



	render() {
		return (
			<div>
				<Helmet>
					<title>TxtCart® | Onboarding</title>
				</Helmet>
				<OnboardingTemplate />
			</div>
		);
	}
}

export default Onboarding;
